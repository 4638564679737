// src/plugins/vuetify.js
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@/assets/scss/main.scss';
import SexyIcon from '@/components/SexyIcon.vue';

Vue.use(Vuetify);

const opts = {
  theme: {
    dark: true,
  },
  icons: {
    values: {
      sexy: {
        component: SexyIcon,
      },
    },
  },
};

export default new Vuetify(opts);
