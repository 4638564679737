



































































import Vue from 'vue';
import AuthConnector from '@/connector/Auth.vue';

export default Vue.extend({
  name: 'BaseNavigationBar',
  components: {
    AuthConnector,
  },
});
