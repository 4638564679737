/* eslint-disable */
import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import AuthService from '@/services/AuthService';
import UserService from '@/services/UserService';

const authService = new AuthService();
const userService = new UserService();

interface LoginPayload {
  gameUrl: string;
  isLoggedIn: boolean;
  token: string;
  gameToken: string;
  betworldToken: string;
  royalLobbyUrl: string;
}

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  public isLoggedIn: boolean = false;

  public gameUrl: string = '';

  public token: string = '';

  public gameToken: string = '';

  public betworldToken: string = '';

  public royalLobbyUrl: string = '';

  @Mutation
  public setLoggedIn(payload: LoginPayload) {
    this.isLoggedIn = payload.isLoggedIn;
    this.gameUrl = payload.gameUrl;
    this.token = payload.token;
    this.gameToken = payload.gameToken;
    this.betworldToken = payload.betworldToken;
    this.royalLobbyUrl = payload.royalLobbyUrl;
  }

  @Mutation
  public setGameToken(payload: LoginPayload) {
    this.gameToken = payload.gameToken;
  }

  @Mutation
  public setBetworldToken(payload: LoginPayload) {
    this.betworldToken = payload.betworldToken;
  }

  @Mutation
  public setRoyalUrl(payload: LoginPayload) {
    this.royalLobbyUrl = payload.royalLobbyUrl;
  }

  @Action({ rawError: true })
  public async login(payload: { username: string; password: string }): Promise<void> {
    console.log('payload', payload);
    const { data } = await authService.login(payload.username, payload.password);
    if (data.token) {
      this.context.commit('setLoggedIn', {
        gameUrl: data.playUrl,
        isLoggedIn: true,
        token: data.token,
      });
      await authService.storeTokenInSessionStorage(data.token);
      const { data: gameData } = await userService.luanchGame();
      this.context.commit('setGameToken', {
        gameToken: gameData.result.token,
      });
      const { data: betworldData } = await userService.launchBetWorld();
      this.context.commit('setBetworldToken', {
        betworldToken: betworldData.token,
      });
      const { data: royalData } = await userService.launchRoyal();
      this.context.commit('setRoyalUrl', {
        royalLobbyUrl: royalData.data.gameUrl,
      });
    }
  }

  @Action({ rawError: true }) async autoLogin(): Promise<void> {
    try {
      const { data } = await authService.checkToken();
      const token = await authService.getTokenFormSessionStorage();
      this.context.dispatch('Profile/subscribeToPromotionChannal', {}, { root: true });
      this.context.commit('setLoggedIn', {
        gameUrl: data.playUrl,
        isLoggedIn: true,
        token,
      });
      if (token) {
        const { data: gameData } = await userService.luanchGame();
        this.context.commit('setGameToken', {
          gameToken: gameData.result.token,
        });
        const { data: betworldData } = await userService.launchBetWorld();
        this.context.commit('setBetworldToken', {
          betworldToken: betworldData.token,
        });
        const { data: royalData } = await userService.launchRoyal();
        this.context.commit('setRoyalUrl', {
          royalLobbyUrl: royalData.data.gameUrl,
        });
      }
    } catch (e) {
      console.log('error auto login', e);
    }
  }

  @Action({ rawError: true })
  public async logout() {
    await authService.removeTokenFormSessionStorage();
    this.context.commit('setLoggedIn', {
      gameUrl: '',
      isLoggedIn: false,
      gameToken: '',
      betworldToken: '',
    });
  }
}
