






















import { Mixins, Component } from 'vue-mixin-decorator';
import { namespace } from 'vuex-class';
// eslint-disable-next-line import/no-cycle
import LunchGameMixin from '@/mixins/launchGameMixin';

const Auth = namespace('Auth');
const Dialog = namespace('Dialog');

@Component
export default class AuthConnector extends Mixins<LunchGameMixin>(LunchGameMixin) {
  @Auth.State
  public isLoggedIn!: boolean;

  @Auth.State
  public token!: string;

  @Auth.State
  public gameUrl!: string;

  @Auth.State
  public gameToken!: string;

  @Auth.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public login!: (payload: object) => {}

  @Auth.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public logout!: () => {}

  @Dialog.Action
  // eslint-disable-next-line @typescript-eslint/ban-types
  public updateDialog!: (payload: { open: boolean; dialogType: string }) => {}

  public async loginFunction(payload: { username: string; password: string }) {
    try {
      await this.login(payload);
      const {
        game,
        tableType,
        provider,
        gameId,
        redirect,
        to,
      } = this.$route.query;
      if (redirect && typeof redirect === 'string') {
        if (to) {
          this.$router.push({ name: redirect, query: { to } });
          return;
        }
        this.$router.push({ name: redirect });
        return;
      }
      if (provider && gameId) {
        await this.launchPgSlot(provider, gameId);
      }
      if (!game && tableType === 'royal') {
        await this.launchRoyal();
      }
      if (!game && tableType === 'betworld') {
        await this.launchBetworld();
      } else if ((game || tableType) && typeof tableType === 'string') {
        await this.findGameIdAndLaunchGameLobby(game, tableType);
      }
      this.$router.push({ name: 'Dashboard' });
      this.$store.commit('Snackbar/showNotification', { message: 'ลงชื่อเข้าใช้สำเร็จ', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  public notifyLogoutOnly() {
    this.$store.commit('Snackbar/showNotification', { message: 'ท่านเป็นสมาชิกอยู่แล้ว', error: true });
  }

  // eslint-disable-next-line class-methods-use-this
  playGames() {
    window.location.href = this.gameUrl;
  }

  public async logoutFunction() {
    this.$store.dispatch('Profile/unSubscribeToPromotionChannel');
    await this.logout();
    this.$router.push({ name: 'Home' });
  }
}
