/* eslint-disable */
import UserService from '@/services/UserService';
import PromotionService from '@/services/PromotionService';
import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import Pusher from 'pusher-js';

const userService = new UserService();
const promotionService = new PromotionService();
const pusher = new Pusher('1d28c69ee1bc8563c1c4', { cluster: 'ap1' });

@Module({ namespaced: true })
export default class Profile extends VuexModule {
  public id: string = '';

  public balance: number = 0;

  public bank: string = '';

  public accountNumber: string = '';

  public accountName: string = '';

  public username: string = '';

  public agentApiUsername: string = '';

  public refCode: string = '';

  public affiliateRevenue: number = 0;

  public refundLoss: number = 0;

  public recentGames: Array<any> = [];

  @Mutation
  public setbank(payload: {
    bank: string;
    account_number: string;
    account_name: string;
  }) {
    this.bank = payload.bank;
    this.accountNumber = payload.account_number;
    this.accountName = payload.account_name;
  }

  @Mutation
  public setAgentApiUsername(payload: string) {
    this.agentApiUsername = payload;
  }

  @Mutation
  public setUsername(payload: string) {
    this.username = payload;
  }

  @Mutation
  public setRefCode(payload: string) {
    this.refCode = payload;
  }

  @Mutation
  public setaffiliateRevenue(payload: number) {
    this.affiliateRevenue = payload;
  }

  @Mutation
  public setBalance(payload: number) {
    this.balance = payload;
  }

  @Mutation
  public setRefundLoss(payload: number) {
    this.refundLoss = payload;
  }

  @Mutation
  public setId(payload: string) {
    this.id = payload;
  }

  @Mutation
  public setRecentGames(payload: any) {
    this.recentGames = [...payload];
  }

  @Action({ rawError: true })
  public async getProfile() {
    const { data } = await userService.getProfile();
    const { user, stat } = data;
    const redeem = stat.current_loss * 0.05;
    this.context.commit('setRefundLoss', redeem > 0 ? redeem.toFixed(2) : 0);
    this.context.commit('setbank', {
      bank: user.bank,
      account_number: user.account_number,
      account_name: user.account_name
    });
    this.context.commit('setUsername', user.username);
    this.context.commit('setaffiliateRevenue', user.affiliate_revenue);
    this.context.commit('setRefCode', user.ref_code);
    this.context.commit('setAgentApiUsername', user.agent_api_username);
    this.context.commit('setId', user._id);
    this.context.commit('setRecentGames', stat.recent_games);
    this.context.dispatch('subscribeToPromotionChannal');
  }

  @Action({ rawError: true })
  public async fetchBalance() {
    const { data } = await userService.checkBalance();
    console.log(data);
    this.context.commit('setBalance', Number(data.result.balance));
  }

  @Action({ rawError: true })
  public async subscribeToPromotionChannal() {
    console.log('sub work')
    const channel = pusher.subscribe('promotion-notification');
    console.log(`subto giveaway-recive-${this.id}`);
    channel.bind(`giveaway-recive-${this.id}`, async (data: any) => {
      console.log(data);
      await promotionService.markreadGiveaway(data._id);
      this.context.dispatch('Dialog/updateDialog', { open: true, dialogType: 'GiveawayDialog', dialogData: data }, { root: true });
    });
  }

  @Action({ rawError: true })
  public async unSubscribeToPromotionChannel() {
    pusher.unsubscribe('promotion-notification');
  }

  @Action({ rawError: true })
  public async findUnreadGiveAway() {
    const result = await promotionService.findUnreadGiveaway();
    return result;
  }

  @Action({ rawError: true })
  public async markreadGiveaway(id: string) {
    await promotionService.markreadGiveaway(id);
  }
}
