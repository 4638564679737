<template>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="1179.000000pt" height="1280.000000pt" viewBox="0 0 1179.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M5695 12419 c-75 -9 -177 -40 -237 -70 -21 -11 -48 -19 -60 -19 -13
0 -30 -4 -38 -10 -11 -7 -17 -2 -26 18 l-12 27 -1 -29 c-1 -25 -4 -27 -23 -22
-71 22 -105 19 -151 -13 -54 -37 -60 -37 -137 -11 -87 29 -238 37 -320 15
-113 -29 -220 -105 -308 -220 -13 -16 -20 -24 -16 -16 18 35 -20 -5 -58 -62
-23 -34 -80 -114 -128 -177 -112 -149 -153 -222 -234 -408 -115 -267 -147
-394 -144 -571 l2 -106 3 93 c3 57 8 92 15 92 6 0 8 -11 5 -28 -10 -51 12
-226 43 -332 20 -70 30 -127 30 -172 0 -62 7 -100 56 -313 12 -53 18 -111 17
-170 -2 -51 1 -84 6 -77 16 26 20 -14 6 -54 -8 -22 -14 -52 -14 -69 -1 -29 1
-28 29 20 26 43 30 47 30 24 0 -43 -30 -129 -85 -244 -126 -263 -165 -372
-165 -459 0 -34 -7 -83 -15 -110 -17 -57 -19 -123 -5 -161 9 -22 5 -37 -22
-88 -18 -34 -38 -66 -45 -70 -29 -18 -82 -144 -92 -217 -5 -41 -7 -77 -3 -80
4 -3 8 -43 10 -90 2 -46 8 -90 13 -96 9 -11 46 -110 56 -151 3 -14 -4 -39 -20
-65 -34 -58 -73 -187 -86 -283 -6 -44 -16 -170 -22 -281 -10 -208 -7 -285 27
-569 20 -172 14 -378 -15 -545 -27 -150 -94 -602 -126 -850 -37 -285 -54 -411
-61 -444 -4 -17 -42 -140 -86 -275 -130 -405 -183 -641 -213 -951 -22 -230
-34 -346 -45 -430 -5 -41 -19 -167 -30 -278 -38 -390 -113 -822 -194 -1127
-30 -110 -70 -263 -91 -340 -20 -77 -52 -176 -70 -220 -18 -44 -36 -102 -39
-130 -3 -27 -8 -78 -12 -113 -6 -60 -5 -66 39 -155 24 -50 48 -103 52 -117 4
-14 36 -83 72 -153 98 -197 253 -390 354 -442 36 -19 39 -19 39 4 0 11 5 23
11 27 25 15 3 92 -42 150 -24 31 -52 66 -61 77 -9 12 -19 33 -23 46 -7 22 -5
23 21 17 40 -10 96 -38 179 -89 97 -60 153 -81 171 -66 8 7 14 19 13 28 -5 51
2 80 20 86 27 8 33 70 11 120 -10 22 -43 65 -74 95 -31 30 -56 59 -56 65 0 6
12 11 28 10 25 0 27 4 30 42 3 34 -2 47 -29 78 -18 20 -45 43 -60 51 -21 10
-32 28 -44 66 -19 66 -19 115 2 203 12 53 17 131 19 315 5 427 1 397 82 630
71 207 108 302 290 735 263 629 377 984 476 1490 44 225 56 306 67 455 14 200
29 288 104 592 59 243 108 462 155 698 10 52 26 160 34 240 9 80 25 197 36
260 11 63 20 129 20 147 0 49 18 49 24 1 3 -24 7 -117 10 -208 5 -172 18 -255
60 -377 30 -90 95 -215 145 -282 33 -43 41 -67 60 -161 35 -175 77 -266 196
-426 113 -150 175 -311 175 -446 -1 -104 -45 -324 -80 -396 -48 -97 -139 -182
-275 -256 -160 -87 -310 -239 -455 -461 -64 -99 -180 -335 -255 -520 -31 -77
-119 -268 -195 -425 -199 -409 -277 -651 -320 -995 -50 -406 -35 -677 85
-1510 25 -171 45 -316 45 -323 0 -9 180 -12 849 -12 l850 0 7 58 c15 110 8
262 -20 417 -28 157 -52 347 -87 675 -11 107 -29 238 -40 290 -11 52 -23 108
-26 123 -6 28 -4 27 43 -20 72 -71 118 -85 301 -91 l153 -4 53 -62 c127 -144
174 -224 343 -586 37 -80 106 -219 154 -310 48 -91 124 -238 171 -327 l84
-163 972 0 c535 0 973 2 973 4 0 2 -11 57 -24 122 -33 162 -88 367 -147 557
l-50 157 30 26 c33 28 196 213 222 254 l17 25 7 -39 c5 -27 1 -67 -14 -132
-11 -51 -21 -115 -21 -143 0 -34 -11 -76 -32 -128 -24 -60 -32 -94 -32 -146
-1 -67 -1 -68 42 -107 l43 -40 20 33 c11 17 25 51 31 75 13 44 30 54 46 25 20
-34 53 66 82 247 6 36 18 88 27 117 l16 52 17 -37 c22 -47 46 -68 88 -76 l34
-6 -6 133 c-14 280 -25 363 -63 446 l-35 76 6 119 7 119 -60 116 c-70 138
-110 249 -156 431 -58 230 -57 219 -41 348 8 64 20 221 26 347 6 127 21 394
33 595 14 237 21 481 21 695 l1 330 -38 225 c-52 311 -78 422 -158 668 -86
264 -94 298 -119 501 -24 189 -55 348 -105 541 -70 266 -126 508 -151 655 -24
137 -27 184 -34 545 -12 609 -54 869 -172 1071 -99 168 -281 275 -503 295
l-73 7 -47 84 c-26 47 -48 95 -49 106 -4 32 -24 73 -178 363 -76 144 -141 277
-144 295 -3 19 -1 61 3 94 l9 60 16 -45 c9 -25 17 -37 17 -27 2 37 21 4 22
-36 0 -23 3 -35 6 -27 2 8 7 22 9 30 5 13 6 13 14 0 6 -8 10 -10 10 -4 0 5 -4
14 -9 20 -6 5 -12 74 -15 154 -2 80 -12 208 -20 285 -11 95 -15 213 -13 365 2
210 0 230 -21 307 -13 45 -39 105 -58 134 -19 29 -34 58 -35 64 -2 32 -15 59
-83 178 -41 73 -87 159 -102 192 -39 87 -161 300 -180 316 -10 8 -5 -5 11 -29
49 -75 20 -56 -64 42 -79 93 -99 122 -63 95 14 -11 14 -10 -2 8 -10 13 -22 20
-26 18 -4 -2 -16 5 -26 17 -11 12 -13 18 -5 13 41 -24 -39 61 -92 99 -173 123
-330 168 -502 145z m-1776 -1179 c-23 -57 -52 -144 -65 -194 -12 -50 -23 -79
-24 -65 0 39 45 190 87 286 20 46 37 82 39 80 2 -2 -15 -50 -37 -107z m2991
-137 c11 -42 20 -99 19 -127 l-1 -51 -13 74 c-8 41 -23 110 -35 154 -12 44
-24 87 -26 96 -2 9 5 -3 16 -27 12 -23 30 -77 40 -119z m-43 -260 c-2 -16 -4
-3 -4 27 0 30 2 43 4 28 2 -16 2 -40 0 -55z m96 -655 c1 -38 -1 -68 -5 -68
-11 0 -10 -8 -13 115 -2 89 -1 101 7 65 5 -25 10 -75 11 -112z m-2970 -18 c9
-41 18 -120 21 -175 4 -90 3 -94 -5 -35 -10 72 -38 251 -46 290 -11 62 16 -11
30 -80z m2957 -438 c0 -7 4 -11 8 -8 4 2 20 -19 36 -47 15 -29 20 -43 12 -32
-9 11 -24 29 -35 40 -19 21 -61 98 -61 112 0 4 9 -5 20 -22 11 -16 20 -36 20
-43z m-2853 -70 c-3 -64 -8 -77 -71 -193 -38 -68 -82 -144 -99 -169 -33 -49
-87 -105 -87 -89 0 5 9 17 20 26 11 10 20 21 20 25 0 12 90 198 95 198 3 0 -1
-13 -10 -30 -25 -48 -17 -48 11 0 15 25 37 61 51 81 28 42 53 127 53 182 0 20
4 37 10 37 5 0 8 -30 7 -68z m2764 -129 c13 -16 12 -17 -3 -4 -10 7 -18 15
-18 17 0 8 8 3 21 -13z m244 -84 c4 -11 10 -17 15 -14 5 3 25 -27 45 -67 20
-40 28 -64 19 -53 -9 11 -21 33 -26 48 -7 18 -13 25 -18 18 -4 -7 -20 14 -40
51 -19 36 -27 57 -17 50 9 -8 19 -22 22 -33z m6 -104 l10 -20 -16 20 c-9 11
-21 29 -26 40 l-10 20 16 -20 c9 -11 21 -29 26 -40z m-3204 -251 c-13 -32 -27
-70 -30 -84 -4 -14 -16 -36 -28 -50 -17 -21 -19 -22 -10 -3 6 12 11 29 11 38
0 21 69 170 75 163 3 -2 -6 -31 -18 -64z m-67 -204 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m260 -526 c0 -13 5 -24 10 -24
24 0 8 -40 -22 -54 -17 -9 -33 -16 -35 -16 -7 0 -3 41 9 84 12 47 38 54 38 10z
m-103 -61 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-180 -45
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m60 -80 c-3 -7 -5 -2 -5
12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-38 -10 c1 7 6 10 11 7 8 -5 7 -11 -1
-21 -18 -22 -29 -17 -28 14 0 24 1 25 9 7 5 -11 9 -14 9 -7z m-129 -140 c0
-19 -2 -20 -10 -8 -13 19 -13 30 0 30 6 0 10 -10 10 -22z m3985 -3077 c20
-354 29 -457 51 -646 28 -234 44 -313 139 -694 67 -270 94 -397 142 -671 51
-291 33 -460 -67 -635 -71 -124 -91 -172 -107 -262 -8 -48 -17 -95 -20 -103
-5 -19 -113 179 -152 280 -72 186 -108 459 -122 930 -4 168 -14 337 -21 375
-10 51 -11 130 -7 295 6 198 13 273 62 625 31 220 62 453 68 518 7 65 15 113
20 106 4 -7 10 -60 14 -118z m517 -3135 c53 -12 109 -31 123 -40 40 -26 65
-75 65 -128 0 -25 5 -69 11 -96 10 -43 9 -61 -10 -134 -11 -46 -21 -106 -21
-132 0 -42 -9 -66 -61 -158 -33 -59 -64 -110 -68 -112 -4 -3 -13 12 -20 32
-26 72 -164 399 -214 505 l-50 108 42 82 c54 103 58 108 85 102 11 -3 65 -16
118 -29z m-5112 -1075 c0 -5 -26 2 -57 15 -52 20 -58 26 -61 55 l-3 31 60 -46
c34 -26 61 -51 61 -55z"/>
<path d="M7120 9425 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6390 12095 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M6420 12056 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M3566 8368 c-3 -13 -5 -36 -5 -53 1 -21 4 -15 10 20 8 52 6 72 -5 33z"/>
</g>
</svg>
</template>

<script>
export default {
  name: 'SexyIcon',
};
</script>
