import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';

interface SetDialogPayload {
  open: string;
  dialogType: string;
  dialogData: any;
}

@Module({ namespaced: true })
export default class Dialog extends VuexModule {
  public open: string = 'false';

  public dialogType: string = '';

  public dialogData: any;

  @Mutation
  public setDialog(payload: SetDialogPayload): void {
    this.dialogType = payload.dialogType;
    this.open = payload.open;
    this.dialogData = { ...payload.dialogData };
  }

  @Action
  public updateDialog(payload: SetDialogPayload): void {
    console.log('before set Dialog', payload);
    this.context.commit('setDialog', payload);
  }
}
