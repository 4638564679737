import {
  VuexModule,
  Module,
  Mutation,
} from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Snackbar extends VuexModule {
  public message: string = '';

  public error: boolean = false;

  @Mutation
  showNotification(payload: { message: string; error: boolean }) {
    this.message = payload.message;
    this.error = payload.error;
  }
}
