























import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BaseSnackbar extends Vue {
  public show: boolean = false;

  public message: string = '';

  public error: boolean = false;

  get messages() {
    return this.message.split(',');
  }

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'Snackbar/showNotification') {
        this.message = state.Snackbar.message;
        this.error = state.Snackbar.error;
        this.show = true;
      }
    });
  }
}
