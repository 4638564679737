/* eslint-disable */
import { AppService } from './AbstractService';

export default class PromotionService extends AppService {
  public async checkTurnover() {
    return this.makeRequest('GET', '/promotions/turnover');
  }

  public async acceptPromotion(data: any) {
    return this.makeRequest('POST', '/promotions/join', data);
  }

  public async markreadGiveaway(id: string) {
    return this.makeRequest('PATCH', `/promotions/giveaway/${id}`);
  }

  public async findUnreadGiveaway() {
    return this.makeRequest('GET', '/promotions/giveaway');
  }

  public async getAvailablePromotions() {
    return this.makeRequest('GET', '/promotions/available');
  }
}
