/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import Auth from '@/store/modules/auth';
import Dialog from '@/store/modules/dialog';
import Profile from '@/store/modules/profile';
import Snackbar from '@/store/modules/snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Dialog,
    Profile,
    Snackbar,
  },
});
