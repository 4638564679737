













import { Component, Vue } from 'vue-property-decorator';
import BaseNavigationBar from '@/components/BaseNavigationBar.vue';
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import BaseSnackbar from '@/components/BaseSnackbar.vue';

@Component({
  components: {
    BaseHeader,
    BaseFooter,
    BaseSnackbar,
    BaseNavigationBar,
  },
})
export default class BaseLayout extends Vue {

}
