






import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'BaseFooter',
})
export default class BaseFooter extends Vue {}
